@use 'styles/theme/theme' as theme;

.categorySearch {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  height: 100%;
  .scroller {
    flex-shrink: 0;
    padding: 0 16px;
  }
  .tabs {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 1280px) {
      gap: 0;
    }
  }
  .tab {
    padding: 6px 8px;
    border-radius: 18px;
    border: 1px solid theme.$tertiaryGrey;
    &:hover {
      border: 1px solid theme.$blue;
      background-color: theme.$blueLight2 !important;
      .tab__label {
        color: theme.$blue;
      }
    }
    &__selected {
      border: 1px solid theme.$blue;
      background-color: theme.$blueLight2 !important;
      .tab__label {
        color: theme.$blue;
        font-weight: 700;
      }
    }
    @media screen and (max-width: 1280px) {
      border: none;
      &:hover {
        border: none;
      }
    }
  }
  .arrow {
    width: 34px;
    svg {
      fill: theme.$primaryGrey !important;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    flex-grow: 1;
    overflow-y: auto;
    @media screen and (max-width: 1280px) {
      margin-bottom: 60px;
      overflow-y: unset;
    }
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :global {
      .MuiCheckbox-colorPrimary {
        padding: 0;
      }
    }
    &__content {
      display: flex;
      column-gap: 6px;
      width: 100%;
    }
    &__entity {
      margin-left: 4px;
    }
  }
  .checkbox {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
  }
  .checkboxCenterAlign {
    align-items: center;
  }
  .openPage {
    &:hover {
      span {
        color: theme.$blue !important;
      }
      svg {
        fill: theme.$blue !important;
      }
    }
  }
  .stagingTypeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px;
    position: sticky;
    width: 100%;
    bottom: 0;
    border-top: 1px solid theme.$tertiaryGrey;
    background-color: theme.$white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @media screen and (max-width: 1280px) {
      position: absolute;
      border-radius: 0;
    }
  }
  .submit {
    min-width: 68px;
    margin-left: 20px;
  }
  .clear {
    &__label {
      margin-top: 2px;
    }
    svg {
      fill: theme.$secondaryGrey !important;
    }
    &:hover {
      .clear__label {
        color: theme.$blue;
      }
      svg {
        fill: theme.$blue !important;
      }
    }
  }
}

.divider {
  border-bottom: 1px solid theme.$borderGrey;
}

.categorySearchWithoutTabs {
  padding-top: 4px;
}

.categorySearchMobile {
  height: unset;
}

.stagingTypes {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  row-gap: 12px;
  margin-left: 24px;
  :global {
    .MuiCheckbox-colorPrimary {
      padding: 0;
    }
  }
}

.skeleton {
  flex-shrink: 0;
}
