@use 'styles/theme/theme' as theme;

.operatorBlock {
  position: relative;
}

.operatorBlockBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid theme.$borderGrey;
  background-color: theme.$white;
  width: 60px;
  height: 30px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: theme.$white;
  border: 1px solid theme.$borderGrey;
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px 0;
  list-style: none;
  box-shadow: 0 4px 6px theme.$blackT10;
  width: 100%;
}

.dropdownItem {
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background: theme.$whiteSmoke;
  }
  &__selected {
    span {
      color: theme.$blue;
      font-weight: bold;
    }
  }
}
