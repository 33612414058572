@use 'styles/theme/theme';

.searchContainer {
  position: relative;
  margin-left: 0;
  width: 100%;
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 372px;
    margin-left: 0;
  }
  z-index: 1000;
}

.searchForm {
  height: 40px;
  padding: 0 12px 0 12px;
  border: 1px solid theme.$tertiaryGrey;
  border-radius: 78px;
  display: flex;
  align-content: center;
  background-color: white;
  position: relative;
  z-index: 1000;
  &:focus-within {
    border: 1px solid theme.$blue;
  }
  transition: border 500ms ease-out;
}

// TODO Move common styles to one place
.searchFormError {
  border: 1px solid theme.$red;
  &:focus-within {
    border: 1px solid theme.$red;
  }
}

.errorMessage {
  color: theme.$red;
  margin-left: 16px;
  margin-top: 8px;
}

.typeDropdownButton {
  all: unset;
  padding-right: 8px;
  border-right: 1px solid theme.$tertiaryGrey;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.currentFilterName {
  max-width: 91px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdownOpen {
  transform: rotate(180deg);
}

.typeDropdownList {
  display: flex;
  flex-direction: column;
}

.typeDropdownItem {
  text-align: left;
  cursor: pointer;
  margin: 0 9.27px;
  border-bottom: 1px solid theme.$borderGrey;
  padding: 10px 0;
  justify-content: flex-start;
  border-radius: 0;
  &:hover {
    margin: 0;
    padding: 10px 9.27px 11px;
    background-color: theme.$blueLight;
    border-bottom: none;
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.searchBtnStart {
  margin: auto 10px auto auto;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.queryInput {
  padding: 0 8px;
  margin: 0;
  border: none;
  border-radius: 8px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  color: theme.$primaryGrey;
  background: white;

  &:placeholder-shown {
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  &::placeholder {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: theme.$tertiaryGrey;
    margin: 0 auto;
    text-align: left;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
}

.btnReset {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 16px 0 0;
  margin: auto 16px auto 0;
  border-right: 1px solid theme.$tertiaryGrey;
  height: 16px;
}

.popper {
  top: -23px !important;
  width: 100%;
  z-index: 999;
}

.nestedSearchPopper {
  top: -23px !important;
  background-color: white;
  width: 100%;
  z-index: 999;
}

.popContent {
  padding-left: 16px;
  padding-right: 16px;
}

.popperContent {
  width: 100%;
  max-height: 95vh;
  padding-top: 23px;
  background-color: white;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
  @media (max-width: 600px) {
    border-radius: 0;
    box-shadow: none;
    max-height: 100vh;
  }
}

.searchProgress {
  margin: auto;
}

.searchIcon {
  fill: theme.$secondaryGrey;
}

.btnSearch {
  all: unset;
  cursor: pointer;
}

.typeDropdownPopover {
  background-color: white;
  border-radius: 10px;
  width: max-content;
}

.searchContainer .searchInputBtn {
  border: 0;
  width: 100%;
  border-radius: 0;
  padding: 0;
  &:hover,
  &.active {
    background-color: transparent;
    box-shadow: none;
  }
}
