@use 'styles/theme/theme' as theme;

.datePicker {
  width: 100%;
  :global {
    .MuiInputLabel-root {
      font-size: 14px !important;
      line-height: 1.6 !important;
    }
    .MuiOutlinedInput-input {
      color: theme.$primaryGrey !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: theme.$secondaryGrey !important;
    }
    .CalendarDay__today {
      border: 1px solid theme.$tertiaryGrey !important;
    }
    .CalendarDay__default {
      border: none;
      &:hover {
        border: 1px solid theme.$tertiaryGrey;
        background: theme.$blue3;
      }
    }
    .CalendarDay__selected_span,
    .CalendarDay__hovered_span {
      color: theme.$primaryGrey;
      background-color: theme.$blueLight2;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      color: theme.$white;
      background-color: theme.$blueChip;
      font-weight: 500;
      border: 2px solid transparent;
    }
    .DayPickerNavigation_button__horizontalDefault {
      top: 22px;
      border: none;
    }
    .DayPickerNavigation_svg__horizontal {
      height: 16px;
      width: 16px;
      fill: theme.$secondaryGrey;
    }
  }
  .datePickerSelect {
    &__month {
      margin-right: 8px;
    }
    svg {
      right: 0px;
    }
    :global {
      .MuiOutlinedInput-root {
        line-height: 1;
      }
      .MuiOutlinedInput-input {
        padding-right: 24px !important;
        padding: 7px;
        height: 1rem;
      }
    }
  }
}
