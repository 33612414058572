@use 'styles/theme/theme' as theme;

.desktopSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 1px solid #ababab;
  background: theme.$white;
  border-radius: 60px;
}

.divider {
  width: 1px;
  height: 20px;
  background: theme.$borderGrey;
}

.searchField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
  column-gap: 4px;
  padding: 14px 16px 14px 20px;
  cursor: pointer;
  border: 1px solid transparent;
  height: 60px;
  &:hover {
    background: theme.$whiteSmoke;
    border-radius: 60px;
    border: 1px solid #ababab;
  }
  &:first-child {
    margin-left: -1px;
  }
  &__selected {
    border-radius: 60px;
    border-color: theme.$blue;
    box-shadow: 0 0 8px 0 theme.$blackT20;
    .input {
      border: none;
      outline: none;
      padding: 0;
      height: 14px;
      font-size: 12px;
      color: theme.$primaryGrey;
      caret-color: theme.$blue;
      &::placeholder {
        color: theme.$tertiaryGrey;
        padding-left: 2px;
      }
    }
    &:hover {
      background: theme.$white;
      border-color: theme.$blue;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    width: 117px;
    text-align: left;
  }
  .addIcon {
    flex-shrink: 0;
    fill: theme.$secondaryGrey !important;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.search {
  margin-left: 10px;
  margin-right: 2px;
  padding: 10px;
  border-radius: 50px;
  background-color: theme.$orange;
  svg {
    fill: theme.$white !important;
  }
}

.clear {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 36px;
  padding: 8px 24px 8px 10px;
  svg {
    fill: theme.$tertiaryGrey;
  }
  &:hover {
    span {
      color: theme.$primaryGrey;
    }
    svg {
      fill: theme.$primaryGrey !important;
    }
  }
}

.popperContainer {
  width: 343px;
  height: calc(100vh - 210px);
  max-height: 615px;
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid theme.$tertiaryGrey;
  background: theme.$white;
  box-shadow: 0 0 6px 0 theme.$blackT25, 0 0 4px 0 theme.$blackT25;

  &_home {
    max-height: 500px;
    height: calc(100vh - 350px);
  }

  @media screen and (max-width: 1280px) {
    height: 615px;
  }
}
