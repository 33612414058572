.noResult {
  text-align: center;
  &__header {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__action {
    margin-top: 22px;
  }
}

.modalContainer {
  max-width: 420px;
}

.modalContent {
  padding: 16px !important;
}
