@use 'styles/theme/theme' as theme;

.datesSelector {
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1280px) {
    height: unset;
    margin-bottom: 60px;
  }

  .content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    overflow-y: auto;
    @media screen and (max-width: 1280px) {
      overflow-y: unset;
    }
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid theme.$secondaryGrey;
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
      border: 1px solid theme.$blue;
      color: theme.$blue;
    }
    &__selected {
      color: theme.$blue;
      border: 1px solid theme.$blue;
      font-weight: 700;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  position: sticky;
  width: 100%;
  bottom: 0;
  border-top: 1px solid theme.$tertiaryGrey;
  background-color: theme.$white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  @media screen and (max-width: 1280px) {
    position: absolute;
    border-radius: 0;
  }
}

.submit {
  min-width: 108px;
}

.clear {
  &__label {
    margin-top: 2px;
  }
  svg {
    fill: theme.$secondaryGrey !important;
  }
  &:hover {
    .clear__label {
      color: theme.$blue;
    }
    svg {
      fill: theme.$blue !important;
    }
  }
}
