@use 'styles/theme/theme' as theme;

.mobileSearch {
  display: flex;
  align-items: center;
  gap: 6px;
  width: calc(100vw - 32px);
  height: 48px;
  padding: 0px 12px 0px 7px;
  border-radius: 60px;
  border: 1px solid theme.$primaryGrey;
  background-color: theme.$white;
  cursor: pointer;
  overflow: hidden;
  &__placeholder {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
  }
  &__searchSection {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: theme.$orange;

    svg {
      fill: theme.$white !important;
      flex-shrink: 0;
    }
  }
}

.drawer {
  height: 100%;
  max-height: none !important;
  border-radius: 0;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    border-bottom: 1px solid theme.$borderGrey;
    &_title {
      display: flex;
      align-items: center;
      margin-left: 4px;
      margin-right: 16px;
    }
    &_subTitle {
      margin-left: 3px;
      span {
        &:not(:first-child)::before {
          content: ' • ';
        }
      }
    }
    svg {
      fill: theme.$primaryGrey !important;
    }
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__searchSection {
    flex-grow: 1;
    overflow-y: auto;
    padding: 24px 16px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid theme.$tertiaryGrey;
    background: theme.$white;
    position: sticky;
    bottom: 0;
  }
  .searchBtn {
    width: 65%;
  }
  .clearBtn {
    svg {
      margin-right: 4px;
    }
    &:hover {
      span {
        color: theme.$blue;
      }
      svg {
        fill: theme.$blue !important;
      }
    }
  }
}

.search {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  margin-bottom: 30px;
  &__field {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
  &__input {
    display: flex;
    align-items: center;
    height: 46px;
    gap: 8px;
    padding: 8px;
    border-radius: 60px;
    border: 1px solid theme.$borderGrey;
    cursor: pointer;
    svg {
      flex-shrink: 0;
    }
  }
  &__inputSelected {
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid theme.$tertiaryGrey;
    display: flex;
    align-items: center;
  }
  &__placeholder {
    margin-left: 5px;
  }
  &__searchIcon {
    padding: 10px;
    border-radius: 50%;
    background: theme.$orangeLight;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: theme.$orange !important;
    }
  }
  &__addIcon {
    fill: theme.$primaryGrey !important;
  }
  &__subTitle {
    display: flex;
    column-gap: 6px;
  }
  &__value {
    display: flex;
    flex-direction: column;
    width: max-content;
  }
}

.searchDrawer {
  height: 100%;
  max-height: none !important;
  border-radius: 0;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 20px 16px;
    border-bottom: 1px solid theme.$borderGrey;
    svg {
      fill: theme.$primaryGrey !important;
    }
    &_section {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    &_title {
      display: flex;
      align-items: center;
      white-space: nowrap;
      column-gap: 4px;
    }
    &_placeholder {
      margin-left: 5px;
    }
  }
}

.input {
  padding: 12px 16px 0;
  font-size: 16px;
  :global {
    .MuiInputLabel-root {
      color: theme.$secondaryGrey;
      line-height: 3.3;
      padding-left: 18px;
      font-size: 12px;
      font-weight: 400;
    }
    .MuiOutlinedInput-root {
      padding-right: 8px;
      border-radius: 60px;
      height: 46px;
      font-size: 16px;
      color: theme.$primaryGrey;
      &:-webkit-autofill {
        height: 46px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid theme.$primaryGrey;
    }
  }
  &__searchIcon {
    padding: 10px;
    border-radius: 50%;
    background: theme.$orangeLight;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: theme.$orange !important;
      flex-shrink: 0;
    }
  }
  &__clearIcon {
    margin: 8px;
    flex-shrink: 0;
    fill: theme.$primaryGrey !important;
    cursor: pointer;
  }
}

.searchIcon {
  flex-shrink: 0;
}
