@import 'styles/theme/theme';

.searchButton {
  width: 100%;
  height: 46px;
  font-size: 12px;
  justify-content: flex-start;
  border: 1px solid $tertiaryGrey;
  background-color: $white;
  &:hover,
  &:active {
    border: 1px solid $tertiaryGrey;
    background-color: $white;
    color: transparent;
    box-shadow: none;
  }
}
